import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import URLSearchParams from '@ungap/url-search-params'
import jwt from 'jsonwebtoken'
import { Upload } from 'grommet-icons'
import Axios from 'axios'

const Wrapper = styled.div`
  padding: 20px;
`

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  text-align: center;
`

const Content = styled.div`
  padding: 20px;
  display: flex;
`

const Informations = styled.div`
  background-color: #ebebeb;
  padding: 10px;
  border-radius: 5px;
  > div {
    padding: 2px;
    > :first-child {
      font-weight: bold;
    }
    > :nth-child(2) {
      background-color: #7084F2;
      color: #fff;
      padding: 2px 8px;
    }
  }
`

const Uploader = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  background-color: #7B79F2;
  margin-left: 20px;
  min-height: 400px;
  border-radius: 5px;
  > input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
`

const UploaderContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
`

const FileTitle = styled.div`
  font-size: 23px;
`

const FileSize = styled.div`
  font-size: 16px;
`

const UploadButton = styled.div`
  background-color: #fff;
  color: #836CF2;
  display: flex;
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;
  margin-top: 15px;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  > :first-child {
    margin-right: 10px;
  }
`

const Error = styled.div`
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  color: #FF6363;
  padding: 6px 12px;
  border-radius: 5px;
`

const GameLink = styled.div`
  a {
    color: #fff !important;
  }
`

export default (props) => {
  const [tokenData, setTokenData] = useState(null)
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [error, setError] = useState(null)
  const urlParams = new URLSearchParams(props.location.search);

  useEffect(() => {
    if (urlParams.get('token')) {
      setTokenData(jwt.decode(urlParams.get('token')))
    }
  }, [])

  const upload = async () => {
    setLoading(true)
    try {
      let bodyFormData = new FormData();
      bodyFormData.append('file', file)
      const { data } = await Axios({
        method: "POST",
        url: `https://file-server.nightzookeeper.com/upload-game`,
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${urlParams.get('token')}`
        }
      })
      setDone(true)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  if (!tokenData) return null
  return <Wrapper>
    <Title>Deploy a game</Title>
    <Content>
    <Informations>
      <div>
        <span>Game ID: </span>
        <span>{tokenData.game}</span>
      </div>
      <div>
        <span>Valid for: </span>
        <span>{tokenData.sub}</span>
      </div>
      <div>
        <span>Valid until: </span>
        <span>{new Date(tokenData.exp * 1000).toISOString()}</span>
      </div>
      <div>
        <span>Issued at: </span>
        <span>{new Date(tokenData.iat * 1000).toISOString()}</span>
      </div>
      <div>
        <span>Issued by: </span>
        <span>{tokenData.iss}</span>
      </div>
    </Informations>
    <Uploader>
      { !file && <input type="file" onChange={e => setFile(e.target.files[0])} accept="application/zip" /> }
      <UploaderContent>
        { !file && <Upload color='#fff' size='40px' /> }
        { file && <FileTitle>{file.name}</FileTitle>}
        { file && <FileSize>{Math.floor(file.size / 1000 / 100) / 10} Mb</FileSize>}
        { file && !loading && !done && <UploadButton onClick={upload}>
          <div>Upload</div>
          <div>
            <Upload color='#836CF2' size='16px' />
          </div>
        </UploadButton> }
        { loading && <div>Uploading... (this might take a little while)</div> }
        { done && <div>Done!</div> }
        { done && <GameLink>
            <a href={`https://cdn.nightzookeeper.com/games/${tokenData.game}`}>{`https://cdn.nightzookeeper.com/games/${tokenData.game}`}</a>
        </GameLink>}

        { error && <Error>
          { error }
          </Error>}
      </UploaderContent>
    </Uploader>
    </Content>

  </Wrapper>
}
